import theHpPackBoite from "../../assets/images/products/hp/the_hp_pack_boite.png";
import theHpPackDoy from "../../assets/images/products/hp/the_hp_pack_doy.png";
import theHpPackVrac from "../../assets/images/products/hp/the_hp_pack_vrac.jpg";

import gryffondorBoite from "../../assets/images/products/hp/the_bravoure_boite.png";
import gryffondorDoy from "../../assets/images/products/hp/the_bravoure_doy.png";
import gryffondorVrac from "../../assets/images/products/hp/the_gryffondor_vrac.jpg";

import serdaigleBoite from "../../assets/images/products/hp/the_erudits_boite.png";
import serdaigleDoy from "../../assets/images/products/hp/the_erudits_doy.png";
import serdaigleVrac from "../../assets/images/products/hp/the_serdaigle_vrac.jpg";

import poufsouffleBoite from "../../assets/images/products/hp/the_loyaute_boite.png";
import poufsouffleDoy from "../../assets/images/products/hp/the_loyaute_doy.png";
import poufsouffleVrac from "../../assets/images/products/hp/the_poufsouffle_vrac.jpg";

import serpentardBoite from "../../assets/images/products/hp/the_ambitieux_boite.png";
import serpentardDoy from "../../assets/images/products/hp/the_ambitieux_doy.png";
import serpentardVrac from "../../assets/images/products/hp/the_serpentard_vrac.jpg";

import theTolkienPackBoite from "../../assets/images/products/tolkien/the_tolkien_pack_boite.jpg";
import theTolkienPackDoy from "../../assets/images/products/tolkien/the_tolkien_pack_doy.jpg";
import theTolkienPackVrac from "../../assets/images/products/tolkien/the_tolkien_pack_vrac.jpg";

import elfesBoite from "../../assets/images/products/tolkien/the_elfes_boite.jpg";
import elfesDoy from "../../assets/images/products/tolkien/the_elfes_doy.png";
import elfesVrac from "../../assets/images/products/tolkien/the_elfes_vrac.jpg";

import semiHommeBoite from "../../assets/images/products/tolkien/the_semi-homme_boite.jpg";
import semiHommeDoy from "../../assets/images/products/tolkien/the_semi-homme_doy.jpg";
import semiHommeVrac from "../../assets/images/products/tolkien/the_semi-homme_vrac.jpg";

import dragonBoite from "../../assets/images/products/tolkien/the_dragon_boite.jpg";
import dragonDoy from "../../assets/images/products/tolkien/the_dragon_doy.jpg";
import dragonVrac from "../../assets/images/products/tolkien/the_dragon_vrac.jpg";

import mageBrunBoite from "../../assets/images/products/tolkien/the_mage_brun_boite.jpg";
import mageBrunVrac from "../../assets/images/products/tolkien/the_mage_brun_vrac.jpg";

import automneBoite from "../../assets/images/products/seasons/the_automne_boite.png";
import automneVrac from "../../assets/images/products/seasons/the_automne_vrac.png";

export const getProductImages = (productName: string, defaultImage: string) => {
  const images: { [key: string]: string[] } = {
    "Collection complète des Sorciers": [
      theHpPackDoy,
      theHpPackBoite,
      theHpPackVrac,
    ],
    "Potion de Bravoure": [gryffondorDoy, gryffondorBoite, gryffondorVrac],
    "Élixir des Érudits": [serdaigleDoy, serdaigleBoite, serdaigleVrac],
    "Breuvage de Loyauté": [poufsouffleDoy, poufsouffleBoite, poufsouffleVrac],
    "Philtre des Ambitieux": [serpentardDoy, serpentardBoite, serpentardVrac],

    "Collection complète Tolkien": [
      theTolkienPackDoy,
      theTolkienPackBoite,
      theTolkienPackVrac,
    ],
    "Thé des Elfes": [elfesDoy, elfesBoite, elfesVrac],
    "Thé du Semi-Homme": [semiHommeDoy, semiHommeBoite, semiHommeVrac],
    "Thé du Dragon": [dragonDoy, dragonBoite, dragonVrac],
    "Infusion du Mage Brun": [mageBrunBoite, mageBrunVrac],

    "Infusion d'Automne": [automneBoite, automneVrac],
  };

  const productExists = Object.keys(images).find((key) => key === productName);

  if (productExists) {
    return images[productName];
  }

  return [defaultImage];
};
